#filterControlStyles {
  position: absolute;
  background: white;
  z-index: 2;
}

/* map key */

.dot {
  height: 16px;
  width: 16px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.mapKeyItem {
  display: flex;
  align-items: center;
}

.campgroundWithout-dot {
  background-color: #fbb03b;
}

.campgroundWith-dot {
  background-color: #2ecc71;
}

.park-dot {
  background-color: #00ffff;
}

.church-dot {
  background-color: #fff;
  border-color: #000;
  border-width: 1px;
}

.rvPark-dot {
  background-color: #b2bec3;
}

.dispersed-dot {
  background-color: #e056fd;
}

.business-dot {
  background-color: #ffff00;
  border-color: #000;
  border-width: 1px;
}

.misc-dot {
  background-color: #4264fb;
}

.bike-shop-icon {
  font-size: 16px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

/* end map key */
