* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  width: 100vw;
  /* height: 100vh; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.zoom-control {
  position: relative;
  right: 40px; /* Adjust as needed */
  z-index: 1; /* Ensure it's above the map */
}

.Tabs {
  width: 80%;
  height: auto;
  min-height: 80%;
  background: #ffffff;
  border: 1px solid #6bb7d8;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #003a53;
  border-radius: 2rem;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

/*Styling specific to our maps*/
.map-container {
  position: absolute;
  height: 600px;
  width: 100%;
}
/* Tab Navigation */
ul.nav {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #6bb7d8;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
ul.nav li:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
ul.nav li:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
ul.nav li:hover {
  background: #6bb7d8;
}
ul.nav li.active {
  background: #6bb7d8;
}

/* Tab Content Styles */
.TabContent {
  font-size: 2rem;
  text-align: center;
  position: relative;
}

/* popup */

#campsiteInfo .mapboxgl-popup {
  width: 300px;
}

.mapboxgl-popup-close-button {
  padding: 15px;
  font-size: 22px;
}

.display-linebreak {
  white-space: pre-line;
}

.respMapHeight {
  height: 800px;
}

@media only screen and (max-width: 480px) {
  .mapboxgl-popup-content {
    max-width: 300px;
  }

  .respMapHeight {
    height: 600px;
  }
}

/* end popup */
